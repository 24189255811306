export const getSize = (size, region) => {
  const regionSizes = size.split("|")
  const regionSize = regionSizes.find((r) => r.includes(region))
  return regionSize && regionSize.slice(2, 6)
}

export const getRegions = (size) => {
  const regionSizes = size.split("|")
  const regions = regionSizes.map((size) => size.split(/[0-9]/)[0])
  return regions || null
}
