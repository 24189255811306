import React, { useMemo } from "react"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import { containerMaxWidth, MEDIA_MIN_LARGE } from "../../../constants"
import Badge from "../Badge"

const Container = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
`

const Gallery = styled(Slider)`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  max-height: 60rem;

  ${MEDIA_MIN_LARGE} {
    margin: 0 -2rem 0 -2rem;
  }

  div {
    padding: 0;
    height: 100%;

    ${MEDIA_MIN_LARGE} {
      padding: 0 0.5rem;
    }

    &:focus {
      outline: none;
    }
  }

  .slick-list {
    transition: height 0.2s;

    ${MEDIA_MIN_LARGE} {
      overflow: visible;
    }
  }

  .slick-track {
    overflow: hidden;
  }

  .slick-arrow {
    width: 4rem;
    height: 4rem;
    background: ${(p) => p.theme.colors.white};
    border-radius: 2rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1;

    ${MEDIA_MIN_LARGE} {
      width: 5rem;
      height: 5rem;
      border-radius: 2.5rem;
    }
  }

  .slick-next {
    right: -2rem;

    ${MEDIA_MIN_LARGE} {
      right: calc((${containerMaxWidth} / 3) + 3rem);
    }
  }

  .slick-prev {
    left: -2rem;

    ${MEDIA_MIN_LARGE} {
      left: 4rem;
    }
  }

  i {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
  }
`

const MobileGallery = styled(Gallery)`
  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const DesktopGallery = styled(Gallery)`
  display: none;

  ${MEDIA_MIN_LARGE} {
    display: block;
  }
`

const ImageGallery = ({
  variantOutOfStock,
  productOutOfStock,
  comingSoon,
  badge,
  mainImage,
  images,
  name,
  languageCode,
  ...other
}) => {
  const { t } = useTranslation("translations")
  const outOfStock = variantOutOfStock || productOutOfStock
  const galleryImages = useMemo(
    () => images?.map((image) => getImage(image.asset)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!galleryImages) return null
  /*
  const slideContent = []
  images.forEach((image) => {
    slideContent.push(<GatsbyImage image={getImage(image.asset)} alt={name} />)
  })


  const [openZoom, setOpenZoom] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  */

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <span {...props}>{children}</span>

  const NextArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-right"></i>
    </SlickButtonFix>
  )
  const PrevArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-left"></i>
    </SlickButtonFix>
  )

  const mobileGallerySettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    nextArrow: React.cloneElement(NextArrow, {
      next: true
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: true
    })
  }

  const desktopGallerySettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    nextArrow: React.cloneElement(NextArrow, {
      next: true
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: true
    })
  }

  return (
    <Container {...other}>
      {outOfStock && (
        <Badge
          outOfStock
          isOnPdp
          text={
            productOutOfStock
              ? t("All sizes are out of stock")
              : t("Out of stock")
          }
        />
      )}
      {comingSoon && !outOfStock && <Badge isOnPdp text={t("Coming soon")} />}
      {badge && badge.en && !outOfStock && !comingSoon && (
        <Badge isOnPdp text={badge[languageCode] || badge.en} />
      )}
      <MobileGallery {...mobileGallerySettings}>
        {galleryImages.map((image) => (
          <GatsbyImage key={uuidv4()} image={image} alt={name} />
        ))}
      </MobileGallery>
      <DesktopGallery {...desktopGallerySettings}>
        {galleryImages.map((image) => (
          <GatsbyImage key={uuidv4()} image={image} alt={name} />
        ))}
      </DesktopGallery>
    </Container>
  )
}

export default ImageGallery
