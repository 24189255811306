import React, { useContext } from "react"
import styled from "styled-components"
import Dinero from "dinero.js"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Prices = styled.div`
  font-size: 1.4rem;
`

const Wrapper = styled.div`
display: flex;
flex-direction: column; 

  ${MEDIA_MIN_MEDIUM} {
    flex-direction: row; 
  }
`

const CurrentPrice = styled.span`
  display: block;
`

const OriginalPrice = styled.span`
  color: ${(p) => p.theme.colors.mediumGrey};
  text-decoration: line-through;
  margin-right: 1rem;
`

const getDiscountPrice = (discount, Amount, price) => {
  const discountMoney = Amount(discount.amount)
  return price.subtract(discountMoney)
}

const Price = ({ price, allDiscount = null, ...rest }) => {
  const { currentStore, languageCode } = useContext(BrinkContext)

  if (!currentStore) return null

  const { currencyUnit } = currentStore
  const Amount = Currency(currencyUnit)
  const priceMoney = Amount(
    price.find((p) => p.currencyUnit === currencyUnit)
      ? price.find((p) => p.currencyUnit === currencyUnit).amount
      : 0
  ).setLocale(languageCode)

  const discount =
    allDiscount && allDiscount.find((d) => d.currencyUnit === currencyUnit)
  const priceWithDiscount =
    discount &&
    getDiscountPrice(discount, Amount, priceMoney).setLocale(languageCode)

  return (
    <Prices {...rest}>
      {discount && discount.amount !== 0 ? (
        <Wrapper>
          <OriginalPrice>
            {priceMoney.toFormat(getFormat(priceMoney))}
          </OriginalPrice>
          <CurrentPrice>
            {priceWithDiscount.toFormat(getFormat(priceWithDiscount))}
          </CurrentPrice>
        </Wrapper>
      ) : (
        <CurrentPrice>
          {priceMoney.toFormat(getFormat(priceMoney))}
        </CurrentPrice>
      )}
    </Prices>
  )
}

const getFormat = (money) => (money.hasCents() ? "$0,0.00" : "$0,0")

const Currency = (currency) => (amount) => Dinero({ currency, amount })

export default Price
