import React, { useState, useContext } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../../../context/BrinkContext"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "../../../../constants"
import Backdrop from "../../../ui/Backdrop"

const FitGuidePopup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.theme.colors.white};
  z-index: 100000;
  padding: 2rem 2rem 3rem;
  opacity: ${(p) => (p.open ? 2 : 0)};
  z-index: ${(p) => (p.open ? 10003 : -1)};
  width: 100%;
  overflow-y: scroll;

  p {
    margin: 0 0 2rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    max-width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    padding: 3rem 3rem 4rem;
    overflow-y: visible;
  }

  ${MEDIA_MIN_LARGE} {
    max-width: ${(p) => (p.hasImage ? "120rem" : "60rem")};
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  width: 4rem;
  height: 4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem 0 0 0.1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.2rem 0 0 0.2rem;
  }

  i {
    color: ${(p) => p.theme.colors.black};
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  width 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.hasImage ? "60%" : "100%")};
  }

  ${MEDIA_MIN_LARGE} {
    width: ${(p) => (p.hasImage ? "50%" : "100%")};
  }
`

const Measurement = styled(Column)`
  display: flex;
  align-items: center;

  ${MEDIA_MIN_MEDIUM} {
    width: 40%;
    padding-left: 2rem;
  }

  ${MEDIA_MIN_LARGE} {
    width: 50%;
  }
`

const MeasurementImage = styled(GatsbyImage)`
  margin: 3rem auto 0;
  width: 100%;
  height: 100%;
  max-height: 35rem;

  ${MEDIA_MIN_LARGE} {
    max-height: 45rem;
  }
`

const UnitSelector = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`

const Unit = styled.div`
  margin: 0.5rem 2rem;
  padding-bottom: 0.5rem;
  height: 2.5rem;
  border-bottom: ${(p) => (p.active ? "0.2rem solid" : "0.2rem transparent")};
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.4rem;
  text-transform: uppercase;

  ${MEDIA_MIN_LARGE} {
    &:hover {
      border-bottom: 0.2rem solid;
    }
  }
`

const Description = styled.p`
  padding: 5rem;
  text-align: center;
`

const FitGuide = ({ fitGuide, openPopup, setOpenPopup }) => {
  const { languageCode } = useContext(BrinkContext)
  const [unit, setUnit] = useState(fitGuide.attributes[0].label.en)
  const { t } = useTranslation("translations")

  if (!fitGuide) return null

  const { description, image, attributes } = fitGuide

  return (
    <>
      <Backdrop handleOnClick={() => setOpenPopup(false)} active={openPopup} />
      <FitGuidePopup hasImage={image} open={openPopup}>
        <CloseButton aria-label="Close" onClick={() => setOpenPopup(false)}>
          <i className="fal fa-times"></i>
        </CloseButton>
        <Wrapper>
          <Column hasImage={image}>
            <h2>{t("Fit guide")}</h2>
            {description && (
              <p>{description[languageCode] || description.en}</p>
            )}
            <UnitSelector>
              {attributes.map((attr) => (
                <Unit
                  key={uuidv4()}
                  active={unit === attr.label.en}
                  onClick={() => setUnit(attr.label.en)}
                >
                  {attr.label.en}
                </Unit>
              ))}
            </UnitSelector>
            {attributes.map((attr) =>
              unit === attr.label.en ? (
                <Description key={uuidv4()}>{attr.value.en}</Description>
              ) : (
                <div key={uuidv4()} />
              )
            )}
          </Column>
          {image && (
            <Measurement>
              <MeasurementImage
                imgStyle={{ objectFit: "contain" }}
                image={getImage(image.asset)}
                loading="lazy"
                alt="Measurement image"
              />
            </Measurement>
          )}
        </Wrapper>
      </FitGuidePopup>
    </>
  )
}

export default FitGuide
