import React, { useState, useContext } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../../../context/BrinkContext"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "../../../../constants"
import Backdrop from "../../../ui/Backdrop"

const SizeGuidePopup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.theme.colors.white};
  z-index: 100000;
  padding: 2rem 2rem 3rem;
  opacity: ${(p) => (p.open ? 2 : 0)};
  z-index: ${(p) => (p.open ? 10003 : -1)};
  width: 100%;
  overflow-y: scroll;

  p {
    margin: 0 0 2rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    max-width: 90%;
    max-height: 90%;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    padding: 3rem 3rem 4rem;
    overflow-y: visible;
  }

  ${MEDIA_MIN_LARGE} {
    max-width: ${(p) => (p.hasImage ? "120rem" : "60rem")};
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  width: 4rem;
  height: 4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.3rem 0 0 0.1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.2rem 0 0 0.2rem;
  }

  i {
    color: ${(p) => p.theme.colors.black};
    margin: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  width 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.hasImage ? "60%" : "100%")};
  }

  ${MEDIA_MIN_LARGE} {
    width: ${(p) => (p.hasImage ? "50%" : "100%")};
  }
`

const Measurement = styled(Column)`
  display: flex;
  align-items: center;

  ${MEDIA_MIN_MEDIUM} {
    width: 40%;
    padding-left: 2rem;
  }

  ${MEDIA_MIN_LARGE} {
    width: 50%;
  }
`

const MeasurementImage = styled(GatsbyImage)`
  margin: 3rem auto 0;
  width: 100%;
  height: 100%;
  max-height: 35rem;

  ${MEDIA_MIN_LARGE} {
    max-height: 45rem;
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`

const Row = styled.tr`
  width: 100%;

  &:first-of-type {
    background: ${(p) => p.theme.colors.backgroundDark};
    color: ${(p) => p.theme.colors.white};
    border: none;

    td {
      border: none;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1.3rem;
      padding: 1.2rem;
      font-weight: 700;

      ${MEDIA_MIN_LARGE} {
        font-size: 1.3rem;
        line-height: 1.6rem;
        padding: 1.5rem 2rem;
      }
    }
  }
`

const Cell = styled.td`
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 1rem 1.2rem;
  font-size: 1.2rem;

  ${MEDIA_MIN_LARGE} {
    font-size: 1.3rem;
    padding: 1.5rem 2rem;
  }
`

const UnitSelector = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`

const Unit = styled.div`
  margin: 0.5rem 2rem;
  padding-bottom: 0.5rem;
  height: 2.5rem;
  border-bottom: ${(p) => (p.active ? "0.2rem solid" : "0.2rem transparent")};
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.4rem;
  text-transform: uppercase;

  ${MEDIA_MIN_LARGE} {
    &:hover {
      border-bottom: 0.2rem solid;
    }
  }
`

const SizeGuide = ({ sizeGuide, openPopup, setOpenPopup }) => {
  const { languageCode } = useContext(BrinkContext)
  const [unit, setUnit] = useState("cm")
  const { t } = useTranslation("translations")

  if (!sizeGuide) return null

  const { sizeGuideCm, sizeGuideInches, description, image } = sizeGuide

  return (
    <>
      <Backdrop handleOnClick={() => setOpenPopup(false)} active={openPopup} />
      <SizeGuidePopup hasImage={image} open={openPopup}>
        <CloseButton aria-label="Close" onClick={() => setOpenPopup(false)}>
          <i className="fal fa-times"></i>
        </CloseButton>
        <Wrapper>
          <Column hasImage={image}>
            <h2>{t("Size guide")}</h2>
            {description && (
              <p>{description[languageCode] || description.en}</p>
            )}
            {sizeGuideCm && sizeGuideInches && (
              <UnitSelector>
                {sizeGuideCm && (
                  <Unit active={unit === "cm"} onClick={() => setUnit("cm")}>
                    Centimeters
                  </Unit>
                )}
                {sizeGuideInches && (
                  <Unit
                    active={unit === "inches"}
                    onClick={() => setUnit("inches")}
                  >
                    Inches
                  </Unit>
                )}
              </UnitSelector>
            )}
            {sizeGuideCm && unit === "cm" && (
              <Table>
                <tbody>
                  {sizeGuideCm.rows.map((row) => (
                    <Row key={uuidv4()}>
                      {row.cells.map((cell) => (
                        <Cell key={uuidv4()}>{cell}</Cell>
                      ))}
                    </Row>
                  ))}
                </tbody>
              </Table>
            )}
            {sizeGuideInches && unit === "inches" && (
              <Table>
                <tbody>
                  {sizeGuideInches.rows.map((row) => (
                    <Row key={uuidv4()}>
                      {row.cells.map((cell) => (
                        <Cell key={uuidv4()}>{cell}</Cell>
                      ))}
                    </Row>
                  ))}
                </tbody>
              </Table>
            )}
          </Column>
          {image && (
            <Measurement>
              <MeasurementImage
                imgStyle={{ objectFit: "contain" }}
                image={getImage(image.asset)}
                loading="lazy"
                alt="Measurement image"
              />
            </Measurement>
          )}
        </Wrapper>
      </SizeGuidePopup>
    </>
  )
}

export default SizeGuide
