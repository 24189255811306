export const getColorName = (color) => {
  switch (color) {
    case "white":
      return "White"
    case "black":
      return "Black"
    case "specialBlack":
      return "Special Black"
    case "blue":
      return "Blue"
    case "beige":
      return "Beige"
    case "orange":
      return "Orange"
    case "specialOrange":
      return "Special Orange"
    case "cream":
      return "Cream"
    case "brown":
      return "Brown"
    case "grey":
      return "Greige"
    case "red":
      return "Red"
    case "pine":
      return "Pine"
    case "lemonade":
      return "Lemonade"
    case "mint":
      return "Mint"
    case "gunmetal":
      return "Gun Metal"
    case "gold":
      return "Gold"
    case "gray":
      return "Grey"
    case "turtoise":
      return "Turtoise"
    case "chalk":
      return "Chalk"
    case "marron":
      return "Marron"
      case "jetBlack":
        return "Jet Black"
      case "carbonGrey":
        return "Carbon Grey"
      case "darkCamo":
        return "Dark Camo"
      case "matteBlack":
        return "Matte Black"
      case "azureBlue":
        return "Azure Blue"
      case "fadedGreen":
        return "Faded Green"
      case "fadedBrown":
        return "Faded Brown"
      case "lightBlue":
        return "Light Blue"
      case "mocha":
        return "Mocha"
      case "darkBlue":
        return "Dark Blue"
      case "ivory":
        return "Ivory"
      case "navy":
        return "Navy"
      case "darkBoulevard":
        return "Dark Boulevard"  
      case "redMerlot":
        return "Red Merlot"
      case "amber":
        return "Amber"
      case "creamGreen":
        return "Cream/Green"
      case "creamBlack":
        return "Cream/Black"
    default:
      return color
  }
}
