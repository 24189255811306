import React, { useState, useContext } from "react"
import styled from "styled-components"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../../../constants"
import Button from "../../../ui/Button"
import { BrinkContext } from "../../../context/BrinkContext"
import { usePage } from "../../../context/PageContext"
import { useTranslation } from "react-i18next"
import ColorSelector from "./ColorSelector"
import SizeSelector from "./SizeSelector"
import OutOfStockForm from "./OutOfStockForm"
import BounceLoader from "react-spinners/BounceLoader"

const ActionContainer = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 20;
  background: white;
  margin: 0;
  left: 0;
  padding: 1rem 2.5rem;
  /* border-top: 1px solid rgb(217, 213, 211); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.1rem 0.4rem 0px;

  ${MEDIA_MIN_LARGE} {
    margin: 3rem 0 1rem;
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 0;
  }
`

const ChangeQty = styled.div`
  display: flex;
  margin-right: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.darkBorder};
`

const Qty = styled.div`
  display: flex;
  height: 4.8rem;
  align-items: center;
  justify-content: center;
  width: 2rem;
  padding-top: 0;
  transition: background 0.2s;

  ${MEDIA_MIN_MEDIUM} {
    width: 5rem;
  }
`
const QtyButton = styled(Qty)`
  display: flex;
  width: 5rem;
  cursor: pointer;
  padding-top: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    &:hover {
      background: ${(p) => p.theme.colors.primary};
      border: 0.1rem solid ${(p) => p.theme.colors.primary};
      color: ${(p) => p.theme.colors.white};
    }
  }
`
const Increase = { ...QtyButton }
const Decrease = { ...QtyButton }

const AddToCartButton = styled(Button)`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
`
const DisabledButton = styled(Button)`
  background: ${(p) => p.theme.colors.disabledBackground};
  color: ${(p) => p.theme.colors.disabledTextColor};
  cursor: initial;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.9);

  &:hover {
    background: ${(p) => p.theme.colors.disabledBackground};
  }
`

const StockNotice = styled.div`
  background: #eee;
  padding: 1.5rem 2rem 1.5rem;
  margin: 2.5rem 0;

  i {
    font-size: 2rem;
    margin-right: 0.8rem;
  }
`

const Actions = ({
  variants,
  currentVariant,
  siblings,
  currentProduct,
  gender,
  sizeGuide,
  productOutOfStock,
  stocks,
  fitGuide,
}) => {
  const { addProductVariantsToCart, setLoadingAddToCart, loadingAddToCart } =
    useContext(BrinkContext)
  const { generalSettings } = usePage()
  const { outOfStockSignup, checkoutFlowEnabled, showAlertBanner } =
    generalSettings || {}
  const { t } = useTranslation("translations")
  const [qty, setQty] = useState(1)

  const variantInfo = currentVariant
    ? variants.find((v) => v.id === currentVariant._id)
    : undefined
  const outOfStock = variantInfo?.outOfStock || productOutOfStock
  const comingSoon = variantInfo?.comingSoon

  const isAvailableForPurchase = () =>
    currentVariant && !outOfStock && !comingSoon && checkoutFlowEnabled

  return (
    <div>
      {siblings?.length > 0 && (
        <ColorSelector
          siblings={siblings}
          currentProduct={currentProduct}
          variants={variants}
          gender={gender}
          stocks={stocks}
        />
      )}
      <SizeSelector
        variants={variants}
        currentVariant={currentVariant}
        sizeGuide={sizeGuide}
        fitGuide={fitGuide}
        gender={gender}
        stocks={stocks}
      />
      {stocks && outOfStockSignup && variantInfo?.outOfStock ? (
        <OutOfStockForm
          variantId={currentVariant?._id}
          currentProduct={currentProduct}
        />
      ) : (
        <ActionContainer>
          {isAvailableForPurchase() && (
            <ChangeQty>
              <Decrease
                role="button"
                aria-label="Decrease quantity"
                onClick={() => {
                  if (qty !== 1) setQty(qty - 1)
                }}
              >
                <i className="fal fa-minus"></i>
              </Decrease>
              <Qty aria-live="assertive">{qty}</Qty>
              <Increase
                role="button"
                aria-label="Increase quantity"
                onClick={() => setQty(qty + 1)}
              >
                <i className="fal fa-plus"></i>
              </Increase>
            </ChangeQty>
          )}
          {!generalSettings.checkoutFlowEnabled ? (
            <DisabledButton disabled aria-label="Available soon">
              {t("Available soon")}
            </DisabledButton>
          ) : !currentVariant && !productOutOfStock ? (
            <DisabledButton disabled aria-label="Please select size">
              {t("Please select size")}
            </DisabledButton>
          ) : outOfStock ? (
            <DisabledButton disabled aria-label="Out of stock">
              {productOutOfStock && !currentVariant
                ? t("All sizes are out of stock")
                : t("Out of stock")}
            </DisabledButton>
          ) : comingSoon ? (
            <DisabledButton disabled aria-label="Coming soon">
              {t("Coming soon")}
            </DisabledButton>
          ) : (
            <AddToCartButton
              hasIcon={!loadingAddToCart}
              aria-label={t("Add to cart")}
              onClick={() => {
                addProductVariantsToCart(currentVariant._id, qty, true)
                setLoadingAddToCart(true)
              }}
            >
              {loadingAddToCart ? (
                <BounceLoader color={"#ffffff"} size={35} />
              ) : (
                <>
                  {t("Add to cart")} <i className="far fa-plus"></i>
                </>
              )}
            </AddToCartButton>
          )}
        </ActionContainer>
      )}
      {showAlertBanner && (
        <StockNotice>
          <i className="fal fa-bells"></i>
          {t(
            `If a product says “Sold Out,“ it might be in someone's cart so check back soon!`
          )}
        </StockNotice>
      )}
    </div>
  )
}

export default Actions
