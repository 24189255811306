import React, { useContext } from "react"
import styled from "styled-components"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import KlarnaLogo from "../../icons/usp/klarna-logo.svg"
import SafeHomeDelivery from "../../icons/usp/safe-home-delivery.svg"
import { BrinkContext } from "../context/BrinkContext"

const Wrapper = styled.div`
  width: 100%;
  max-width: ${(p) => (p.containToGrid ? containerMaxWidth : "100%")};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background: ${(p) => p.backgroundColor || "transparent"};
`

const Container = styled.div`
  display: flex;
  margin: ${(p) => (!p.isOnProductPage ? "1.2rem auto 1rem" : "0")};
  max-width: 100rem;
  width: 100%;
  padding: ${(p) => (!p.isOnProductPage ? "0" : "0")};

  ${MEDIA_MIN_LARGE} {
    padding: 0;
    margin: ${(p) => (!p.isOnProductPage ? "1.2rem auto 1rem" : "0")};
  }
`

const UspItem = styled.div`
  height 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 0.5rem;
  color: ${(p) => p.textColor || p.theme.colors.black};

  ${MEDIA_MIN_LARGE} {
    padding: 2rem 1rem 1rem;
  }
`

const Title = styled.p`
  font-size: 0.8rem;
  line-height: 1rem;
  max-width: 11rem;
  margin: 0.5rem auto 0;
  text-align: center;
  letter-spacing: 0.1rem;
  font-weight: 600;
  text-transform: uppercase;

  ${MEDIA_MIN_MEDIUM} {
    max-width: 20rem;
  }

  ${MEDIA_MIN_LARGE} {
    max-width: 25rem;
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
`

const Text = styled.p`
  font-size: 0.9rem;
  line-height: 1.2rem;
  max-width: 10rem;
  margin: 0.5rem auto;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.05rem;

  ${MEDIA_MIN_MEDIUM} {
    max-width: 22rem;
  }

  ${MEDIA_MIN_LARGE} {
    max-width: 25rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`
const IconContainer = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;

  i {
    color: ${(p) => p.iconColor || p.theme.colors.black};
  }

  svg {
    width: 5rem;
    margin-bottom: 0.4rem;
    fill: ${(p) => p.iconColor || p.theme.colors.black};

    ${MEDIA_MIN_LARGE} {
      width: 6rem;
    }
  }
`

const UspIcon = styled.i`
  font-size: 2.2rem;
  margin-bottom: 0.5rem;

  ${MEDIA_MIN_LARGE} {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`

const KlarnaIcon = styled(KlarnaLogo)`
  width: 6rem;
  margin-bottom: 0.6rem;
  fill: ${(p) => p.textColor || p.theme.colors.black};

  ${MEDIA_MIN_LARGE} {
    width: 8rem !important;
  }
`

const getIcon = (usp) => {
  switch (usp.icon) {
    case "klarna":
      return <KlarnaIcon />
    case "safe-home-delivery":
      return <SafeHomeDelivery />
    default:
      return <UspIcon className={`fal fa-${usp.icon}`}></UspIcon>
  }
}

const Usp = ({ data, isOnProductPage }) => {
  const { languageCode } = useContext(BrinkContext)
  const { containToGrid, backgroundColor, textColor, iconColor, uspEntries } =
    data

  return (
    <Wrapper containToGrid={containToGrid} backgroundColor={backgroundColor}>
      <Container isOnProductPage={isOnProductPage}>
        {uspEntries.map((usp) => (
          <UspItem textColor={textColor} key={usp.text.en}>
            <IconContainer iconColor={iconColor}>{getIcon(usp)}</IconContainer>
            <Title>{usp.title[languageCode] || usp.title.en}</Title>
            <Text>{usp.text[languageCode] || usp.text.en}</Text>
          </UspItem>
        ))}
      </Container>
    </Wrapper>
  )
}

export default Usp
