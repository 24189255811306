export const fetchStock = async (products, getStocks) => {
  const allVariants = products.map((product) => product.variants).flat()

  const splitEvery = (n, xs, y = []) => {
    return xs.length === 0
      ? y
      : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]))
  }

  const stock = await Promise.all(
    splitEvery(500, allVariants).map((batch) => {
      return getStocks(batch.map((variant) => variant._id))
    })
  )
    .then((values) => {
      return values
    })
    .catch(console.error.bind(console))

  return stock.flat(2)
}
