import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Container = styled.div`
  position: absolute;
  top: ${(p) => (p.isOnPdp ? "2rem" : "auto")};
  bottom: ${(p) => (p.isOnPdp ? "auto" : "0")};
  left: ${(p) => (p.isOnPdp ? "2rem" : "0")};
  right: ${(p) => (p.isOnPdp ? "auto" : "0")};
  max-width: ${(p) => (p.isOnPdp ? "calc(100% - 4rem)" : "100%")};
  padding: 0.5em 1rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.4rem;
  background: ${(p) =>
    p.outOfStock
      ? p.theme.colors.disabledBackground
      : p.backgroundColor || p.theme.colors.background};
  color: ${(p) =>
    p.outOfStock
      ? p.theme.colors.disabledTextColor
      : p.textColor || p.theme.colors.black};
  text-transform: uppercase;
  z-index: 1;
  text-align: center;
  text-shadow: ${(p) =>
    p.outOfStock ? "0px 0px 2px rgba(255, 255, 255, 0.9)" : "none"};
  font-weight: ${(p) => (p.outOfStock ? "700" : "400")};

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;
    bottom: ${(p) => (p.isOnPdp ? "auto" : "2rem")};
    left: ${(p) => (p.isOnPdp ? "2rem" : "2rem")};
    right: ${(p) => (p.isOnPdp ? "auto" : "2rem")};
    padding: 0.8rem 2rem;
  }
`

const Badge = ({
  outOfStock,
  text,
  backgroundColor,
  textColor,
  isOnPdp,
  ...other
}) => (
  <Container
    outOfStock={outOfStock}
    backgroundColor={backgroundColor}
    textColor={textColor}
    isOnPdp={isOnPdp}
    {...other}
  >
    {text}
  </Container>
)

export default Badge
