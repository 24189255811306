import React, { useState, useContext, useEffect, useMemo } from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import loadable from "@loadable/component"
import queryString from "query-string"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE,
  MEDIA_MIN_X_LARGE
} from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import ImageGallery from "../components/product/page/ImageGallery"
import Usp from "../components/widgets/Usp"
import Price from "../components/product/Price"
import Actions from "../components/product/page/actions/Actions"
import Description from "../components/product/page/Description"
import { getGenderAttributes } from "../helpers/getGenderAttributes"
import WidgetLoader from "../components/widgets/WidgetLoader"
import { getVariantStock } from "../helpers/stock"
import { fetchStock } from "../helpers/fetchStock"
import * as events from "../components/context/utils/events"
import { clickProduct } from "../components/context/utils/events"

import Specifications from "../components/widgets/Specifications"

const ProductSlider = loadable(() =>
  import("../components/widgets/ProductSlider")
)
const Video = loadable(() => import("../components/widgets/Video"))

const Container = styled.div`
  padding: 0 0 6rem;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${containerMaxWidth};
  margin: 0 auto 2rem;
  padding: 0 2.5rem;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;

  ${MEDIA_MIN_LARGE} {
    flex-wrap: nowrap;
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 0;
  }
`

const AdditionalInfo = styled(Grid)`
  ${MEDIA_MIN_LARGE} {
    padding-top: 5rem;
  }
`

const InformationContainer = styled.div`
  max-width: ${containerMaxWidth};
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  ${MEDIA_MIN_LARGE} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`

const Information = styled.div`
  width: 100%;
  position: relative;
  padding: 3rem 0 0;
  background: #ffffff;

  ${MEDIA_MIN_LARGE} {
    width: calc((${containerMaxWidth} / 3) + 3rem);
    padding: 3rem;
    min-height: 68.5rem;
    height: 100%;
    position: absolute;
    top: -0.2rem;
  }
`

const Name = styled.h1`
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin: 0;
  padding: 0 2rem 0 1rem;
  text-align: left;
  border-left: 0.5rem solid ${(p) => p.theme.colors.primary};
  font-weight: 600;

  ${MEDIA_MIN_MEDIUM} {
    margin-bottom: 1rem;
    font-size: 3rem;
    line-height: 3rem;
  }
`

const FormattedPrice = styled(Price)`
  margin-top: 2.2rem;
  margin-bottom: 2.4rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-bottom: 3rem;
  }

  span {
    font-size: 2rem;
  }
`

const ProductVideo = styled(Video)`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: 50%;
  }
`

const MatchingProducts = styled(ProductSlider)`
  ${MEDIA_MIN_MEDIUM} {
    padding-top: 4rem;

    h2 {
      display: inline-block;
      margin: 3rem auto 1.5rem;
    }
  }
`

const RelatedProducts = styled(ProductSlider)`
  ${MEDIA_MIN_MEDIUM} {
    padding: 0;

    h2 {
      display: inline-block;
      margin: 3rem auto 1.5rem;
    }
  }
`

const ProductPage = React.memo(
  ({ data: { sanityProduct, brinkcommerce }, pageContext, location }) => {
    const { t } = useTranslation("translations")
    const [sanityProductVariant, setSanityProductVariant] = useState()
    const [stocks, setStocks] = useState(null)
    const [formattedVariants, setFormattedVariants] = useState([])
    const [productOutOfStock, setProductOutOfStock] = useState(false)
    const { languageCode, currentStore, getStocks, cart } =
      useContext(BrinkContext)
    const { gender } = pageContext
    const { variant } = queryString.parse(location.search)
    const { loginEnabled } = pageContext.sanityLoginPage
    const LayoutComponent = loginEnabled ? SecureLayout : Layout

    const {
      displayName,
      variants,
      slug,
      matchingProducts,
      relatedProducts,
      primaryUsps,
      comingSoon,
      badge,
      widgets,
      genderAttributes,
      siblings
    } = sanityProduct

    const { images, description, video, sizeGuide, fitGuide } = getGenderAttributes(
      genderAttributes,
      gender,
      widgets
    )
    const brinkVariants = brinkcommerce.getProduct.relatedVariants
    const { shortDescription, _id } = sanityProductVariant || variants[0]
    const brinkVariant = brinkVariants?.filter((v) => v.id === _id)[0]
    const productName = displayName[languageCode] || displayName.en

    useMemo(
      async () =>
        await fetchStock(
          [sanityProduct].concat(sanityProduct.siblings),
          getStocks
        ).then((products) => {
          setStocks(products)
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    useEffect(() => {
      if (
        variants.length === 1 &&
        !location.search.includes(variants[0]?.slug?.current)
      ) {
        setSanityProductVariant(variants[0])
        navigate(`?variant=${variants[0]?.slug?.current}/`, { replace: true })
      } else {
        setSanityProductVariant(
          variants.find((bv) => `${bv.slug.current}/` === variant)
        )
      }
    }, [variant, variants])

    useEffect(() => {
      const formattedVariants = variants
        .filter((variant) => variant.active)
        .map((variant) => ({
          id: variant._id,
          name: displayName[languageCode] || displayName.en,
          outOfStock: getVariantStock(variant, stocks, cart) === 0,
          comingSoon: comingSoon,
          slug: `${sanityProduct.slug.current}/?variant=${variant.slug.current}`,
          size: variant.size,
          color: variant.color,
          image: variant.mainImage,
          title: variant.title
        }))
      setFormattedVariants(formattedVariants)
    }, [
      variants,
      stocks,
      cart.cartItems,
      displayName,
      languageCode,
      sanityProduct,
      cart,
      comingSoon
    ])

    useEffect(() => {
      if (!stocks) return false
      setProductOutOfStock(
        formattedVariants.every((variant) => variant.outOfStock)
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedVariants])

    useEffect(() => {
      const delayDebounceFn =
        !variant &&
        setTimeout(() => {
          if (variants.length !== 1) {
            events.viewProduct({
              sanityProduct,
              sanityProductVariant:
                sanityProductVariant ??
                sanityProduct ??
                sanityProduct?.variants[0],
              languageCode,
              prices: brinkVariant.price,
              discount: brinkVariant.discount,
              currentStore,
              index: clickProduct?.positionInList ?? null
            })
          } else {
            events.viewProduct({
              sanityProduct,
              sanityProductVariant: variants[0],
              languageCode,
              prices: brinkVariant.price,
              discount: brinkVariant.discount,
              currentStore,
              index: clickProduct?.positionInList ?? null
            })
          }
        }, 150)

      if (variant && sanityProductVariant) {
        const trackedVariant = variants.find(va => va.slug.current === variant.replace('/', ''))
        if (trackedVariant) {
          events.viewProduct({
            sanityProduct,
            sanityProductVariant: trackedVariant,
            languageCode,
            prices: brinkVariant.price,
            discount: brinkVariant.discount,
            currentStore,
            index: clickProduct?.positionInList ?? null
          })
        }
      }
      return () => clearTimeout(delayDebounceFn)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sanityProductVariant])

    if (!variants.length || !brinkVariant || !brinkVariant?.price) return null

    return (
      <LayoutComponent
        meta={{
          title: productName,
          description: shortDescription[languageCode] ?? shortDescription.en,
          gender: gender
        }}
        invertedHeader
        pageContext={pageContext}
      >
        <Breadcrumbs
          breadcrumbs={
            gender === "unisex"
              ? [{ slug: `/${slug.current}/`, name: productName }]
              : [
                { slug: `/${gender}-home/`, name: gender },
                { slug: `/${gender}-home/${slug.current}/`, name: productName }
              ]
          }
        />
        <Container>
          <Grid>
            <ImageGallery
              name={productName}
              images={images}
              variantOutOfStock={
                stocks
                  ? formattedVariants.find(
                    (v) => v.id === sanityProductVariant?._id
                  )?.outOfStock
                  : false
              }
              productOutOfStock={productOutOfStock}
              comingSoon={comingSoon}
              badge={badge}
              languageCode={languageCode}
            />
            <InformationContainer>
              <Information>
                <Name>{productName}</Name>
                <FormattedPrice
                  price={brinkVariant.price}
                  allDiscount={
                    brinkVariant.discount?.length > 0
                      ? brinkVariant.discount
                      : null
                  }
                />
                {formattedVariants.length > 0 && (
                  <Actions
                    currentVariant={sanityProductVariant}
                    variants={formattedVariants}
                    siblings={siblings}
                    currentProduct={sanityProduct}
                    sizeGuide={sizeGuide}
                    fitGuide={fitGuide}
                    gender={gender}
                    stocks={stocks}
                    productOutOfStock={productOutOfStock}
                  />
                )}
                {primaryUsps?.map((usp) => (
                  <Usp key={usp.id} data={usp} isOnProductPage />
                ))}
              </Information>
            </InformationContainer>
          </Grid>
          <AdditionalInfo>
            {video && (
              <ProductVideo
                title={displayName.en}
                url={video.videoFile.asset.url}
                poster={video.overlayImage.asset}
                productName={productName}
              />
            )}
            <Description
              languageCode={languageCode}
              productDescription={description}
            />
          </AdditionalInfo>
          <WidgetLoader widgets={widgets} />
          {matchingProducts?.length > 0 && (
            <MatchingProducts
              products={matchingProducts}
              title={t("you may also like")}
              columns="4"
            />
          )}
          {relatedProducts?.length > 0 && (
            <RelatedProducts
              products={relatedProducts}
              title={t("Related products")}
              columns="4"
            />
          )}
        </Container>
        <Specifications />
      </LayoutComponent>
    )
  }
)

export default ProductPage

export const query = graphql`
  query ($sanityProductId: String!, $brinkProductId: ID!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      ...Product
    }
    brinkcommerce {
      getProduct(id: $brinkProductId) {
        relatedVariants {
          id
          name
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`
