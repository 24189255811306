import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { v4 as uuidv4 } from "uuid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  containerMaxWidth,
  MEDIA_MIN_LARGE,
  MEDIA_MIN_X_LARGE
} from "../../constants"

const Wrapper = styled.div`
  padding: 2rem 0 0;
  border-top: 0.1rem solid ${(p) => p.theme.colors.darkBorder};
  background: ${(p) => p.backgroundColor || "transparent"};
  color: ${(p) => p.textColor || "black"};
  margin-bottom: 4rem;

  ${MEDIA_MIN_LARGE} {
    padding: 4rem 0 8rem;
  }
`

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 2.5rem;

  ${MEDIA_MIN_LARGE} {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 0;
  }
`

const Specs = styled.div`
  width: 100%;
  order: 2;

  h2 {
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 0;
  }

  ${MEDIA_MIN_LARGE} {
    width: 45%;
    flex-shrink: 0;
    order: 1;

    h2 {
      font-size: 5rem;
      letter-spacing: 0.2rem;
    }
  }
`

const SpecsImageWrapper = styled.div`
  width: 100%;
  order: 1;

  img {
    max-width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    order: 2;
    width: 45%;
    margin-top: 5rem;
  }
`

const AttributeList = styled.ul`
  list-style: none;
  padding-left: 0;
`

const Attribute = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.darkBorder};
  padding: 1.5rem 0;

  &:last-child {
    border-bottom: none;
  }
`

const Label = styled.p`
  font-weight: 700;
  width: 30%;

  ${MEDIA_MIN_LARGE} {
    width: 40%;
  }
`

const Value = styled.p`
  width: 70%;

  ${MEDIA_MIN_LARGE} {
    width: 60%;
  }
`

const Specifications = ({ data, ...other }) => {
  const { languageCode } = useContext(BrinkContext)

  if (!data) return null

  const { title, backgroundColor, textColor, image, attributes } = data

  return (
    <Wrapper backgroundColor={backgroundColor} textColor={textColor}>
      <Container {...other}>
        <Specs>
          <h2>{title[languageCode] ?? title.en}</h2>
          <AttributeList>
            {attributes.map((attribute) => (
              <Attribute key={uuidv4()}>
                <Label>
                  {attribute.label[languageCode] ?? attribute.label.en}
                </Label>
                <Value>
                  {attribute.value[languageCode] ?? attribute.value.en}
                </Value>
              </Attribute>
            ))}
          </AttributeList>
        </Specs>
        <SpecsImageWrapper>
          <GatsbyImage image={getImage(image.asset)} />
        </SpecsImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default Specifications
