export const getVariantStock = (variant, stocks, cart) => {
  if (stocks?.length === 0) return null

  const stock = stocks?.find((p) => p.productId === variant._id) ?? {}
  const cartQuantity = (
    cart.cartItems.find(
      (cartItem) =>
        cartItem.id === variant._id && stock.queryDateTime < cartItem.updated
    ) || { quantity: 0 }
  ).quantity

  return Math.max((stock.stock || 0) - cartQuantity, 0)
}
